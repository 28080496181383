<!-- Features start -->
<section class="section" id="cyberawarness">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="text-center mb-5">
          <h5 class="text-primary text-uppercase small-title">Saiber Alliance</h5>
          <h4 class="mb-3">Cyber Security Training & Awareness</h4>
          <p>“An organization can invest in multi-million-dollar technology but all it takes is one click by a human to
            bring down an entire organization.”</p>
        </div>
      </div>
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-lg-5">
        <div>
          <div class="avatar-md mb-4">
            <span class="avatar-title rounded-circle bg-soft-primary">
              <i-feather name="shield" class="icon-dual-primary"></i-feather>
            </span>
          </div>
          <h5>Cyber-Savvy Culture</h5>
          <p class="mb-4">With multiple years of hands-on experience and expertise in human behavior, we help to build,
            grow, and measure/sustain impactful cyber awareness & training program. Ultimately, the key is to create
            cyber hygiene culture and increase awareness through customized engagement and in turn maximize
            effectiveness of cyber security program to achieve real and sustainable behavior change. We help foster a
            cyber-savvy environment and a robust cyber culture by providing employees with the knowledge and instinct to
            be the first line of defense.</p>

          <div class="row">
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Training
              </p>
            </div>
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Cyber Security
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-5 ml-lg-auto col-sm-8">
        <div class="card border border-light shadow-none mt-5 mt-lg-0">
          <div class="card-header border-0 bg-transparent">
            <div>
              <i class="mdi mdi-circle text-danger mr-1"></i>
              <i class="mdi mdi-circle text-warning mr-1"></i>
              <i class="mdi mdi-circle text-success mr-1"></i>
            </div>
          </div>
          <div class="card-body bg-light">
            <div class="box-shadow">
              <img src="assets/images/img/cyber-security.png" alt="cyber security" class="img-fluid mx-auto d-block">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

    <div class="row mt-5 pt-5">
      <div class="col-lg-5 col-sm-8">
        <div class="card border border-light shadow-none">
          <div class="card-header border-0 bg-transparent">
            <div>
              <i class="mdi mdi-circle text-danger mr-1"></i>
              <i class="mdi mdi-circle text-warning mr-1"></i>
              <i class="mdi mdi-circle text-success mr-1"></i>
            </div>
          </div>
          <div class="card-body bg-light">
            <div class="box-shadow">
              <img src="assets/images/img/cyber.jpg" alt="cyber" class="img-fluid mx-auto d-block">
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 ml-lg-auto">
        <div class="mt-4 mt-lg-0">
          <div class="avatar-md mb-4">
            <span class="avatar-title rounded-circle bg-soft-primary">
              <i-feather name="user-check" class="icon-dual-primary"></i-feather>
            </span>
          </div>
          <h5>Cyber Vigilance</h5>
          <p class="mb-4">Empower your employees and business partners with the knowledge and skills to stay cyber
            vigilant not only at work but also at home. We can also support procurement and legal through supplier
            contract negotiations, using cyber security governance, risk & compliance methodology, as well as working
            and communicating with business stakeholders in non-technical terms.</p>

          <div class="row">
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Communication
              </p>
            </div>
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Trust
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</section>
<!-- Features end -->
