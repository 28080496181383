<!--Navbar Start-->
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark navbar-light"
  (window:scroll)="windowScroll()" id="navbar">
  <div class="container">
    <!-- LOGO -->
    <a class="logo text-uppercase" href="/">
      <span class="logo-saiber">Saiber</span> <span class="logo-alliance"> Alliance</span>
      <!-- <img src="assets/images/logo-light.png" class="logo-light" alt="" height="20">
      <img src="assets/images/logo-dark.png" alt="" class="logo-dark" height="20" /> -->
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav ml-auto navbar-center" id="mySidenav">
        <li class="nav-item" [ngClass]="{'active':currentSection === 'home'}">
          <a [ngxScrollTo]="'#home'" href="javascript: void(0);" class="nav-link">Home</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'services'}">
          <a [ngxScrollTo]="'#services'" href="javascript: void(0);" class="nav-link">Services</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'features'}">
          <a [ngxScrollTo]="'#features'" href="javascript: void(0);" class="nav-link">News & Events</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'clients'}">
          <a [ngxScrollTo]="'#clients'" href="javascript: void(0);" class="nav-link">Clients</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'aboutus'}">
          <a [ngxScrollTo]="'#aboutus'" href="javascript: void(0);" class="nav-link">About Us</a>
        </li>
      </ul>
      <button class="btn btn-success btn-rounded navbar-btn" [ngxScrollTo]="'#aboutus'">Contact Us</button>

    </div>
  </div>
</nav>
<!-- Navbar End -->
<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">

  <!-- Hero section Start -->
  <section class="hero-section-5" id="home" style="background: url(assets/images/img/cyber-2.jpg);">
    <div class="bg-overlay"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="hero-wrapper text-center mb-4">
            <p class="font-16 text-uppercase text-white-50">Cyber Awareness Champion</p>
            <h1 class="hero-title text-white mb-4">Our objective is to become a long-term and reliable business growth
              partner<span class="text-primary"> - Siaber Alliance</span></h1>

            <!-- <p class="text-white-50">To an English person, it will seem like simplified English, as a skeptical
              Cambridge friend of mine occidental</p> -->

            <div class="mt-4">
              <!-- <a href="javascript: void(0);" class="btn btn-primary mt-2 mr-2">Get Started</a> -->
              <!-- <a href="javascript: void(0);" class="btn btn-success mt-2 mr-2">Learn more</a> -->
              <ul>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'cyberawarness'}">
                  <a [ngxScrollTo]="'#cyberawarness'" href="javascript: void(0);"
                    class="btn btn-success mt-2 mr-2">Learn more</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- Hero section End -->
  <app-services></app-services>
  <app-cyber-awarness></app-cyber-awarness>
  <app-clients></app-clients>
  <app-footer></app-footer>
</div>
