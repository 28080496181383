<!-- Footer start -->
<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-sm-6">
        <div id="aboutus">
          <h5 class="mb-4 footer-list-title">About Saiber Alliance</h5>
          <p>The Saiber Alliance was founded with the primary objective of providing exclusive, specialized cyber
            security training, consultancy and business process services to organizations and for its suppliers. The
            purpose of the Saiber Alliance is to reduce cyber risk exposure by effectively equipping the front line with
            latest cyber vigilance training and awareness resources.</p>
        </div>
      </div>
      <div class="col-lg-2 offset-lg-1 col-sm-6">
        <div>
          <h5 class="mb-4 footer-list-title">Company</h5>
          <ul class="list-unstyled footer-list-menu">
            <li><a [ngxScrollTo]="'#home'" href="javascript: void(0);">Home</a></li>
            <li><a [ngxScrollTo]="'#aboutus'" href="javascript: void(0);">About Us</a></li>
            <li><a href="javascript: void(0);">News & Events</a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-2 col-sm-6">
        <div>
          <h5 class="mb-4 footer-list-title">More Info</h5>
          <ul class="list-unstyled footer-list-menu">
            <li><a [ngxScrollTo]="'#services'" href="javascript: void(0);">Services</a></li>
            <li><a [ngxScrollTo]="'#clients'" href="javascript: void(0);">Clients</a></li>
            <li><a [ngxScrollTo]="'#cyberawarness'" href="javascript: void(0);">Cyber Awareness</a></li>
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6">
        <div>
          <h5 class="mb-4 footer-list-title">Contact</h5>

          <div>
            <div class="media">
              <i-feather name="mail" class="icon-dual-light icons-sm mt-1 mr-2"></i-feather>
              <div class="media-body">
                <p>info@saiberalliance.com</p>
              </div>
            </div>
            <div class="media">
              <i-feather name="phone" class="icon-dual-light icons-sm mt-1 mr-2"></i-feather>
              <div class="media-body">
                <p>+1 (832) 341-2238</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->

</footer>
<!-- Footer end -->

<!-- Footer alt start -->
<section class="bg-primary py-3">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="float-sm-left">
          <a href="javascript: void(0);">
            <a class="logo text-uppercase" href="/">
              <span class="logo-saiber">Saiber</span> <span class="logo-alliance"> Alliance</span>
              <!-- <img src="assets/images/logo-light.png" class="logo-light" alt="" height="20">
              <img src="assets/images/logo-dark.png" alt="" class="logo-dark" height="20" /> -->
            </a>
          </a>
        </div>
        <div class="float-sm-right mt-4 mt-sm-0">
          <p class="copyright-desc text-white mb-0">{{year}} ©SaiberAlliance.</p>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</section>
<!-- Footer alt start -->
