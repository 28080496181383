import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cyber-awarness',
  templateUrl: './cyber-awarness.component.html',
  styleUrls: ['./cyber-awarness.component.scss']
})
export class CyberAwarnessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
