const serviceData = [
    { icon: 'grid', title: 'Customized Cyber Security Training & Awareness', text: 'To an English person, it will seem like English as skeptical.' },
    { icon: 'edit', title: 'Speak in Non-Technical Terms', text: 'If several languages coalesce, the grammar of the language.' },
    { icon: 'headphones', title: 'Content Design and Development', text: 'The languages only differ in their grammar their pronunciation' },
    { icon: 'layers', title: 'Human Behavior Engineering', text: 'Everyone realizes why a new common would be desirable.' },
    { icon: 'code', title: 'Supplier Contract Negotiations', text: 'To achieve this, it would be necessary to have uniform.' },
    { icon: 'tablet', title: 'Change & Project Management', text: 'Their separate existence is a myth. For science, music, etc.' },
    { icon: 'tablet', title: 'Leadership Communication', text: 'Their separate existence is a myth. For science, music, etc.' },
    { icon: 'tablet', title: 'Supplier Classification', text: 'Their separate existence is a myth. For science, music, etc.' },
    { icon: 'tablet', title: 'Business Partner Security', text: 'Their separate existence is a myth. For science, music, etc.' },
];

export { serviceData };
