const testimonialData = [
    {
        // tslint:disable-next-line: max-line-length
        message: '" I am anticipating seeing more training modules coming up, because of its impact on people’s life. If we continue, these training sessions, sooner or later mindset will continue to change for the better. "',
        username: 'Wassim Dossani'
    },
    {
        // tslint:disable-next-line: max-line-length
        message: '" The training was so innovative, informative, and instructive; with real life examples, possibilities, and unforeseen scenario to stay cyber vigilant. "',
        username: 'Rasheed Walker'
    },
    {
        // tslint:disable-next-line: max-line-length
        message: '" What an interactive training it was. I can’t wait to incorporate what I learned about cyber security for a safe enviornment. Thank you. "',
        username: 'Ali Mirza'
    },
];

export { testimonialData };
