  <!-- Pricing start -->
  <section class="section bg-light" id="pricing">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="text-center mb-5">
            <h5 class="text-primary text-uppercase small-title">Pricing</h5>
            <h4 class="mb-3">Choose your Plan</h4>
            <p>It will be as simple as occidental in fact, it will be Occidental.</p>
          </div>
        </div>
      </div>
      <!-- end row -->

      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-4">
            <ul class="nav nav-pills pricing-nav-tabs mb-4">
              <li class="nav-item">
                <a class="nav-link active" href="javascript: void(0);">Monthly</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="javascript: void(0);">Yearly</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4" *ngFor="let item of pricingData">
          <div class="pricing-plan card text-center">
            <div class="card-body p-4">
              <h5 class="mt-2 mb-5">{{item.title}}</h5>

              <h1 class="mb-5"><sup><small>$</small></sup>{{item.price}}/ <span class="font-16">Mo</span></h1>

              <div>
                <a href="javascript: void(0);" class="btn btn-primary">Join now</a>
              </div>
              <div class="plan-features mt-5">
                <p>Bandwidth : <span class="text-success">{{item.bandwidth}}</span></p>
                <p>Onlinespace : <span class="text-success">{{item.onlinespace}}</span></p>
                <p>Support : <span class="text-success">{{item.support}}</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- Pricing end -->

  <!-- Cta start -->
  <section class="section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="text-center mb-5">
            <h5 class="text-primary text-uppercase small-title">Subscribe</h5>
            <h4 class="mb-3">Subscribe to our Newsletter</h4>
            <p>It will be as simple as occidental in fact, it will be Occidental.</p>
          </div>
        </div>

        <div class="col-xl-8 col-lg-10">
          <div class="text-center">
            <div class="subscribe">
              <form>
                <div class="row">
                  <div class="col-md-9">
                    <div>
                      <input type="text" class="form-control" placeholder="Enter your E-mail address">
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="mt-3 mt-md-0">
                      <button type="button" class="btn btn-primary btn-block">Subscribe Us</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- Cta end -->
