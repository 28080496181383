<!-- Services start -->
<section class="section bg-light" id="services">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="text-center mb-5">
          <h5 class="text-primary text-uppercase small-title">Services</h5>
          <h4 class="mb-3">Services We Provide</h4>
          <p>The Saiber Alliance was founded with the primary objective of providing exclusive, specialized cyber
            security training, consultancy and business process services to organizations and for its suppliers. The
            purpose of the Saiber Alliance is to reduce cyber risk exposure by effectively equipping the front line with
            latest cyber vigilance training and awareness resources.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-4 col-sm-6" *ngFor="let item of serviceData">
        <div class="text-center p-4 mt-3">
          <div class="avatar-md mx-auto mb-4">
            <span class="avatar-title rounded-circle bg-soft-primary">
              <i-feather name="{{item.icon}}" class="icon-dual-primary"></i-feather>
            </span>
          </div>
          <h5 class="font-18">{{item.title}}</h5>
          <!-- <p class="mb-0">{{item.text}}</p> -->
        </div>
      </div>

    </div>
    <!-- end row -->

    <!-- <div class="row mt-4">
      <div class="col-lg-12">
        <div class="text-center">
          <a href="javascript: void(0);" class="btn btn-success">View more</a>
        </div>
      </div>
    </div> -->
    <!-- end row -->
  </div>
  <!-- end container -->
</section>
<!-- Services end -->
